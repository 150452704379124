import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import loader from "../loading.svg";

const Signup = () => {
  const [businessname, setBusinessname] = useState("");
  const [username, setUsername] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setLoading] = useState("none");
  const [smallPopup, setSmallPopup] = useState(false);
  const [popupText, setPopupText] = useState("none");

  const handleSubmit = async (e) => {
    setLoading("block");
    setSmallPopup(true);
    e.preventDefault();
    console.log(businessname, username, position, email);

    try {
      const response = await axios.post(
        "api/register",
        { businessname, username, position, email, phone },
        {
          withCredentials: true,
        }
      );
      console.log("response", response); // Display a success message
      // Redirect to another page upon successful login
      setLoading(false);

      console.log("success: ", response);
      setLoading("none");
      setPopupText(
        "Thank you for signing up. Our team will get back to you shortly."
      );
      setErrorMessage(
        "Thank you for signing up. Our team will get back to you shortly."
      );

      setTimeout(() => {
        setSmallPopup(false);
      }, 3000);
    } catch (error) {
      setLoading("none");
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("error", error.response.data.message);
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
    }
  };

  return (
    <div
      className="main-container"
      style={{ width: "100%", maxWidth: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page login-page--signup">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form onSubmit={handleSubmit} autoComplete="off">
          <h2 className="form-title">Get Started</h2>
          {errorMessage && (
            <div
              variant="button"
              fontWeight="regular"
              style={{
                marginBottom: "1rem",
                color: errorMessage.includes("Thank you") ? "green" : "red",
                display: "block",
              }}
            >
              {errorMessage}
            </div>
          )}
          <div className="input-group">
            <input
              type="text"
              placeholder="Name"
              className="input user-icon"
              autoComplete="none"
              name="username"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Mobile No."
              className="input contact-icon"
              autoComplete="none"
              name="phone"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Company Name"
              className="input user-icon"
              autoComplete="none"
              name="businessname"
              required
              onChange={(e) => setBusinessname(e.target.value)}
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Position"
              className="input user-icon"
              autoComplete="none"
              name="position"
              required
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
          {/* <div className="input-group">
            <input
              type="text"
              placeholder="Connect Whatsapp Business"
              className="input contact-icon"
              autoComplete="none"
            />
          </div> */}
          <div className="input-group">
            <input
              type="email"
              placeholder="Email Address"
              className="input email-icon"
              autoComplete="none"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
            />
          </div> */}
          <button type="submit" className="btn btn--full-width mt-25">
            Sign Up
          </button>
          {/* <Link to="/dashboard" className="btn btn--full-width mt-25">
            Sign Up
          </Link> */}
          {/* <div className="continue-with">Or continue with</div>
          <div className="btn-group">
            <button className="login-btn">
              <img src="assets/icons/icon-google.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-apple.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-facebook.svg" alt="google icon" />
            </button>
          </div> */}
          <div className="new-signup">
            Already have an account?
            <Link to="/login" className="btn">
              Sign In
            </Link>
          </div>
        </form>
      </div>
      {/* {smallPopup && (
        <div className="leads-popup leads-popup--delete">
          <div className="leads-popup__container flex justify-content-center align-center">
            <img src={loader} style={{ height: "150px", display: isLoading }} />
            <h3
              style={{
                color: popupText.includes("success") ? "green" : "red",
              }}
            >
              {popupText}
            </h3>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Signup;
