import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

// redux state
import { useSelector, useDispatch } from "react-redux";
import {
  currentConversations,
  getConversations,
} from "../slices/conversationsSlice";

const Chat = () => {
  // Define the WebSocket URL
  const url = "wss://agent.altctrl.ai";

  // Create WebSocket connection
  const socket = new WebSocket(url);

  const dispatch = useDispatch();
  const conversations = useSelector(currentConversations);
  const conversationsStatus = useSelector(
    (state) => state.conversations.status
  );

  const [chats, setChats] = useState(false);
  const [selectedChat, setSelectedChat] = useState(false);
  const [chatid, setChatId] = useState(false);
  const [chatIdx, setChatIdx] = useState(-1);

  const lastMessageRef = useRef(false);
  const openedChatNameRef = useRef(false);

  // put convsersations in state once successfully fetched
  useEffect(() => {
    if (conversationsStatus === "succeeded") {
      setChats(conversations.data);
    }
  }, [conversationsStatus, dispatch]);

  useEffect(() => {
    // Connection opened
    socket.addEventListener("open", (event) => {
      console.log("Connected to WebSocket server");
    });

    // Listen for messages
    socket.addEventListener("message", (event) => {
      console.log("Received message from server:", event.data);
      dispatch(getConversations());
      // setMessages((prevMessages) => [...prevMessages, event.data]);
    });

    // Handle WebSocket connection close
    socket.addEventListener("close", (event) => {
      console.log("Disconnected from WebSocket server");
    });

    // Handle WebSocket errors
    socket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    // Cleanup function to close WebSocket connection
    return () => {
      socket.close();
    };
  }, [chats]);

  useEffect(() => {
    if (chatid && chats.length > 0) {
      const chat = chats.find((el) => el._id === chatid);
      setSelectedChat(chat?.messages || []);
    }
  }, [chatid, chats]);

  useEffect(() => {
    if (chatIdx >= 0 && chats[chatIdx].name) {
      console.log(chatIdx, chats[chatIdx].name);
      openedChatNameRef.current.innerHTML = chats[chatIdx].name;
    } else if (chatIdx >= 0) {
      console.log(chatIdx, chats[chatIdx].recepient);
      openedChatNameRef.current.innerHTML = chats[chatIdx].recepient;
    }
  }, [chatIdx]);

  console.log("last message ref: ", lastMessageRef);
  console.log("conversations: ", chats);
  console.log("selected chat: ", selectedChat);

  // open chat handler
  function handleOpenChat(e, id, idx) {
    e.preventDefault();
    console.log("chat handler: ", e);
    setChatId(id);
    setChatIdx(idx);
  }

  function scrollToBottom() {
    if (lastMessageRef.current) {
      console.log("last message ref: ", lastMessageRef);
      lastMessageRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "end",
      });
    }
  }

  useLayoutEffect(() => {
    scrollToBottom();
  }, [chatid, selectedChat]);

  return (
    <main className="flex">
      <Sidebar title="Broad" />
      <div className="main-container">
        <Header />

        <div className="chat">
          <div className="chat__recent">
            {chats
              ? chats.map((el, idx) => {
                  let str;
                  if (el.messages[el.messages.length - 1].content.interactive) {
                    str =
                      el.messages[el.messages.length - 1].content.interactive
                        .body.text;
                  } else {
                    str = el.messages[el.messages.length - 1].content.text
                      ? el.messages[el.messages.length - 1].content.text.body
                      : el.messages[el.messages.length - 1].content;
                  }
                  // console.log(
                  //   "one chat: ",
                  //   el,
                  //   el.messages[el.messages.length - 1].content
                  // );
                  return (
                    <div
                      key={el._id}
                      style={{ cursor: "pointer" }}
                      className={
                        chatid == el._id
                          ? "chat__recent-chat chat__selected"
                          : "chat__recent-chat"
                      }
                      onClick={(e) => {
                        setSelectedChat(el.messages);
                        handleOpenChat(e, el._id, idx);
                      }}
                    >
                      <div className="chat-pic">
                        <img
                          src="assets/images/profile-pic.png"
                          alt="profile"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <div className="chat-name flex justify-content-between ">
                          <span>{el.name ? el.name : el.recepient}</span>{" "}
                          <span>1 m Ago</span>
                        </div>
                        <div className="chat-message flex justify-content-between align-center">
                          <span>{str}...</span>
                          <span className="unread"></span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            {/* <div className="chat__recent-chat">
              <div className="chat-pic">
                <img src="assets/images/profile-pic.png" alt="profile" />
              </div>
              <div className="flex-grow-1">
                <div className="chat-name flex justify-content-between ">
                  <span>Angelie Crison</span> <span>1 m Ago</span>
                </div>
                <div className="chat-message flex justify-content-between align-center">
                  <span>Thank you very much. I’m glad ...</span>{" "}
                  <span className="unread"></span>
                </div>
              </div>
            </div>

            <div className="chat__recent-chat">
              <div className="chat-pic">
                <img src="assets/images/profile-pic.png" alt="profile" />
              </div>
              <div className="flex-grow-1">
                <div className="chat-name flex justify-content-between ">
                  <span>Angelie Crison</span> <span>1 m Ago</span>
                </div>
                <div className="chat-message flex justify-content-between align-center">
                  <span>Thank you very much. I’m glad ...</span>{" "}
                  <span className="seen"></span>
                </div>
              </div>
            </div> */}
          </div>

          <div className="chat__area">
            <div className="chat__header flex justify-content-between">
              <div className="flex">
                <div className="chat-pic">
                  <img src="assets/images/profile-pic.png" alt="profile" />
                </div>

                <div className="chat-name">
                  <span className="chat-name" ref={openedChatNameRef}>
                    Username
                  </span>{" "}
                  <div className="online">Online</div>
                </div>
              </div>

              {/* <div className="chat__header-action">
                <button className="flex-center call-btn">
                  <img src="assets/icons/icon-video.svg" alt="video-call" />
                </button>

                <button className="flex-center call-btn">
                  <img src="assets/icons/icon-call.svg" alt="audio-call" />
                </button>
              </div> */}
            </div>

            <div className="chat__body">
              {chatid
                ? selectedChat.map((chat, idx) => {
                    if (chat.broadcast) {
                      let content = JSON.parse(chat.content);
                      console.log("content structure: ", content);
                      return (
                        <div
                          key={idx}
                          ref={
                            idx == selectedChat.length - 1
                              ? lastMessageRef
                              : null
                          }
                          className={
                            chat.role == "user" ? "from-them" : "from-me"
                          }
                        >
                          <img src={content.images[0]} />
                          <p>Title: {content.title}</p>
                          <p>Price: {content.price}</p>
                          <p>Type: {content.type}</p>
                          <p>Area: {content.area}</p>
                          <p>Bathrooms: {content.bathrooms}</p>
                          <p>Bedrooms: {content.bedrooms}</p>
                          <p>Brochure link: {content.brochure}</p>
                          <p>Calendar link: {content.calendarlink}</p>
                          <p>Location: {content.location}</p>
                          <p>Video link: {content.video}</p>
                        </div>
                      );
                    } else {
                      return (
                        <p
                          key={idx}
                          ref={
                            idx == selectedChat.length - 1
                              ? lastMessageRef
                              : null
                          }
                          className={
                            chat.role == "user" ? "from-them" : "from-me"
                          }
                        >
                          {chat.content.interactive
                            ? chat.content.interactive.body.text
                            : chat.content.text
                            ? chat.content.text.body
                            : chat.content}
                        </p>
                      );
                    }
                  })
                : null}
              {/* <p class="from-them">
                It was loud. We just laid there and said &ldquo;is this an
                earthquake? I think this is an earthquake.&rdquo;
              </p>
              <p class="from-me no-tail">
                Damian strolls in behind: &ldquo;Well well well, isn&rsquo;t
                that lovely.&rdquo;
              </p>
              <p class="from-me">
                Like is this an earthquake just go back to sleep
              </p>
              <p class="from-them no-tail">You sure?</p>
              <p class="from-them margin-b_one">
                It&rsquo;s more like &ldquo;this is an earthquake. Check the
                Internet. Yup. Earthquake. This is the size. This is the
                epicenter. Check social media. Make sure the East Coast knows
                I&rsquo;m alive. Okay, try and go back to sleep.&rdquo;
              </p>
              <p class="from-me no-tail emoji">👍🏻</p>
              <p class="from-me">Glad you&rsquo;re safe</p> */}
            </div>

            {/* <div className="chat__footer">
              <input type="text" placeholder="Send your message…" />
              <button type="submit">
                <svg
                  width="28"
                  height="24"
                  viewBox="0 0 28 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.6668 2L17.5002 22L12.8335 13L2.3335 9L25.6668 2Z"
                    stroke="#0BDEDE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.6668 2L12.8335 13"
                    stroke="#0BDEDE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Chat;
