import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DatePickerCustom from "../components/DatePicker";
import CSVDownloader from "../components/CSVDownload";
import CSVReader from "../components/CSVReader";

import loader from "../loading.svg";

import upload from "../upload.png";
import download from "../download.png";

// redux state
import { useSelector, useDispatch } from "react-redux";
import { currentListings, getListings } from "../slices/listingsSlice";
import { currentUser, getCurrentUser } from "../slices/authSlice";

const Broad = () => {
  const dispatch = useDispatch();
  const auth = useSelector(currentUser);
  const authStatus = useSelector((state) => state.auth.status);
  const listings = useSelector(currentListings);
  const listingsStatus = useSelector((state) => state.listings.status);

  const [seedState, setSeedState] = useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState("block");
  const [popupText, setPopupText] = useState("");

  const [isPickFile, setIsPickFile] = useState(false);
  const [isFileResult, setIsFileResult] = useState(false);
  const [isError, setIsError] = useState(false);

  const [properties, setProperties] = useState(false);
  const [isWhatsappConn, setIsWhatsappConn] = useState(true);

  console.log("listings: ", properties);
  console.log("user: ", auth, isWhatsappConn);

  const [activeTab, setActiveTab] = useState("new");
  const [activeButton, setActiveButton] = useState("sell");

  const [brochure, setBrochure] = useState("Upload Property Brochure *");
  const [files, setFiles] = useState({});
  const [previews, setPreviews] = useState({});

  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const [location, setLocation] = useState("");
  const [list, setList] = useState({});
  const [type, setType] = useState("off-plan");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [area, setArea] = useState("");
  const [price, setPrice] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    if (authStatus === "succeeded") {
      setIsWhatsappConn(auth.user.whatsappnumberid);
    }
  }, [authStatus, dispatch]);

  // put listings in state once successfully fetched
  useEffect(() => {
    if (listingsStatus === "succeeded") {
      setProperties(listings.properties);
    }
  }, [listingsStatus, dispatch]);

  const handleDataFromChild = (data) => {
    setList(data); // Update parent state with the child's data
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setBrochure(event.target.files[0]);
    }
  };

  const handleImageChange = (e, previewNumber) => {
    const file = e.target.files[0];
    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [previewNumber]: file,
      }));

      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [previewNumber]: URL.createObjectURL(file),
      }));
    }
  };

  const handleLaunch = async (e) => {
    e.preventDefault();
    if (!list.length) {
      setIsError(true);
      setIsPickFile(true);
      return;
    }
    setIsPopup(true);
    setIsLoading("flex");
    console.log(
      "form data: ",
      files,
      title,
      video,
      location,
      list,
      type,
      bedrooms,
      bathrooms,
      area,
      price,
      brochure,
      details
    );

    const formData = new FormData();

    formData.append("title", title);
    formData.append("video", video);
    formData.append("location", location);
    formData.append("list", list);
    formData.append("type", type);
    formData.append("bedrooms", bedrooms);
    formData.append("bathrooms", bathrooms);
    formData.append("area", area);
    formData.append("price", price);
    formData.append("brochure", brochure);
    formData.append("details", details);

    for (const [key, file] of Object.entries(files)) {
      formData.append(key, file);
    }

    try {
      const response = await axios.post("/api/broadcast", formData);

      // Handle successful response
      console.log("Form submitted successfully:", response.data);
      setIsLoading("none");
      setPopupText(response.data);
      dispatch(getListings());
      setTimeout(() => {
        setIsPopup(false);
        setPopupText("");

        // reset state
        setBrochure("Upload Property Brochure");
        setFiles({});
        setPreviews({});

        setTitle("");
        setVideo("");
        setLocation("");
        setList({});
        setType("off-plan");
        setBedrooms("");
        setBathrooms("");
        setArea("");
        setPrice("");
        setDetails("");
      }, 3000);
    } catch (error) {
      // Handle error
      console.error("Error submitting form:", error);
      setIsLoading("none");
      if (error.response.status === 413) {
        setPopupText("File uploads too large.");
      } else {
        setPopupText("Something went wrong.");
      }
      setTimeout(() => {
        setIsPopup(false);
        setPopupText("");
      }, 3000);
    }
  };

  const handleSchedule = (e) => {
    e.preventDefault();
    console.log("scheduling goes here");
  };

  console.log("contact list: ", list);

  return (
    <main className="flex">
      <Sidebar title="Broad" />
      <div className="main-container broad-container">
        <Header />
        {isWhatsappConn ? (
          <div className="broad">
            <div className="broad__tabs flex align-center">
              <button
                className={`broad__broad-btn ${
                  activeTab === "new" ? "active" : ""
                }`}
                onClick={() => handleTabClick("new")}
              >
                NEW BROADCAST
              </button>
              |
              <button
                className={`broad__history-btn ${
                  activeTab === "history" ? "active" : ""
                }`}
                onClick={() => handleTabClick("history")}
              >
                HISTORY
              </button>
              <div className="contact-upload-container">
                <button
                  type="submit"
                  className="btn btn--schedule flex align-center contact-upload-btn"
                  onClick={() => {
                    setIsPickFile(true);
                  }}
                >
                  <img src={upload} />
                  <p>Upload Contacts</p>
                </button>
                <button
                  type="submit"
                  className="btn btn--schedule flex align-center"
                >
                  <img src={download} />
                  <CSVDownloader />
                </button>
              </div>
            </div>
            <div
              className={`broad__broad ${activeTab === "new" ? "" : "d-none"}`}
            >
              <div>
                <form onSubmit={handleLaunch}>
                  <div className="broad__images">
                    <div className="broad__image flex-center">
                      <label
                        className="flex-center flex-direction-column"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            previews.preview1
                              ? previews.preview1
                              : "assets/icons/icon-upload.svg"
                          }
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          required
                          name="image1"
                          onChange={(e) => handleImageChange(e, "preview1")}
                          style={{ cursor: "pointer" }}
                        />
                        {!previews.preview1 && (
                          <p style={{ color: "#eeeeee" }}>Upload Image 1 *</p>
                        )}
                      </label>
                    </div>
                    <div className="broad__image flex-center">
                      <label
                        className="flex-center flex-direction-column"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            previews.preview2
                              ? previews.preview2
                              : "assets/icons/icon-upload.svg"
                          }
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name="image2"
                          onChange={(e) => handleImageChange(e, "preview2")}
                          style={{ cursor: "pointer" }}
                        />
                        {!previews.preview2 && (
                          <p style={{ color: "#eeeeee", textAlign: "center" }}>
                            Upload Image 2 <br /> (Optional)
                          </p>
                        )}
                      </label>
                    </div>
                    <div className="broad__image flex-center">
                      <label
                        className="flex-center flex-direction-column"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            previews.preview3
                              ? previews.preview3
                              : "assets/icons/icon-upload.svg"
                          }
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name="image3"
                          onChange={(e) => handleImageChange(e, "preview3")}
                          style={{ cursor: "pointer" }}
                        />
                        {!previews.preview3 && (
                          <p style={{ color: "#eeeeee", textAlign: "center" }}>
                            Upload Image 3 <br /> (Optional)
                          </p>
                        )}
                      </label>
                    </div>
                    <div className="broad__image flex-center">
                      <label
                        className="flex-center flex-direction-column"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            previews.preview4
                              ? previews.preview4
                              : "assets/icons/icon-upload.svg"
                          }
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name="image4"
                          onChange={(e) => handleImageChange(e, "preview4")}
                          style={{ cursor: "pointer" }}
                        />
                        {!previews.preview4 && (
                          <p style={{ color: "#eeeeee", textAlign: "center" }}>
                            Upload Image 4 <br /> (Optional)
                          </p>
                        )}
                      </label>
                    </div>
                    <div className="broad__image flex-center">
                      <label
                        className="flex-center flex-direction-column"
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            previews.preview5
                              ? previews.preview5
                              : "assets/icons/icon-upload.svg"
                          }
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name="image5"
                          onChange={(e) => handleImageChange(e, "preview5")}
                          style={{ cursor: "pointer" }}
                        />
                        {!previews.preview5 && (
                          <p style={{ color: "#eeeeee", textAlign: "center" }}>
                            Upload Image 5 <br /> (Optional)
                          </p>
                        )}
                      </label>
                    </div>
                  </div>
                  <input
                    required
                    type="text"
                    placeholder="Broadcast Title *"
                    className="mb-20"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <input
                    required
                    type="text"
                    placeholder="Video URL *"
                    className="mb-20"
                    name="video"
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}
                  />
                  {/* <div className="mb-20 flex">
                  <input
                    required
                    type="text"
                    placeholder="Location"
                    className="mb-20"
                    name="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />

                  <select
                    required
                    className="mb-20"
                    name="list"
                    onChange={(e) => setList(e.target.value)}
                    placeholder="Select One"
                  >
                    <option value='{"name": "User 1", "phone": "254723783429"},_{"name": "User 2", "phone": "254721233159"}'>
                      Whatsapp Contact List (Select to send Individual Message)
                    </option>
                    <option value='{"name": "User 1", "phone": "254723783429"},_{"name": "User 2", "phone": "254721233159"}'>
                      Whatsapp Contact List #1
                    </option>
                    <option value='{"name": "User 1", "phone": "254723783429"},_{"name": "User 2", "phone": "254721233159"}'>
                      Whatsapp Contact List #2
                    </option>
                  </select>
                </div> */}

                  <div className="mb-20 flex">
                    <select
                      required
                      name="type"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="off-plan" defaultValue>
                        Property Type * : Off-Plan
                      </option>
                      <option value="ready">Property Type * : Ready</option>
                      <option value="secondary">
                        Property Type * : Secondary
                      </option>
                      <option value="rental">Property Type * : Rental</option>
                      <option value="other">Property Type * : Other</option>
                    </select>

                    <label className="input-file" htmlFor="file">
                      <span>{brochure.name ? brochure.name : brochure}</span>
                      <input
                        required
                        type="file"
                        placeholder="Property Name"
                        id="for"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>

                  <div className="broad__options mb-20 flex align-center">
                    <div className="flex align-center">
                      <label htmlFor="bedrooms">Bedrooms *</label>
                      <input
                        required
                        name="bedrooms"
                        value={bedrooms}
                        id="bedrooms"
                        type="number"
                        onChange={(e) => setBedrooms(e.target.value)}
                      />
                      {/* <select
                        required
                        name="bedrooms"
                        id="bedrooms"
                        onChange={(e) => setBedrooms(e.target.value)}
                      >
                        <option value="0-1" defaultValue>
                          0 - 1
                        </option>
                        <option value="0-2">0 - 2</option>
                        <option value="0-3">0 - 3</option>
                      </select> */}
                    </div>
                    <div className="flex align-center">
                      <label htmlFor="bathrooms">Bathrooms *</label>
                      <input
                        required
                        name="bathrooms"
                        value={bathrooms}
                        id="bathrooms"
                        type="number"
                        onChange={(e) => setBathrooms(e.target.value)}
                      />
                      {/* <select
                        required
                        name="bathrooms"
                        id="bathrooms"
                        onChange={(e) => setBathrooms(e.target.value)}
                      >
                        <option value="0-1" defaultValue>
                          0 - 1
                        </option>
                        <option value="0-2">0 - 2</option>
                        <option value="0-3">0 - 3</option>
                      </select> */}
                    </div>

                    <div className="flex align-center">
                      <label htmlFor="area">Area *</label>
                      <input
                        required
                        type="text"
                        value={area}
                        id="area"
                        name="area"
                        onChange={(e) => setArea(e.target.value)}
                      />
                    </div>

                    <div className="flex align-center">
                      <label htmlFor="price">Price *</label>
                      <input
                        required
                        type="text"
                        value={price}
                        id="price"
                        name="price"
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>

                    <div className="sell-rent-btns">
                      <button
                        className={activeButton === "sell" ? "active" : ""}
                        onClick={() => handleButtonClick("sell")}
                      >
                        Buy/Sell
                      </button>
                      <button
                        className={activeButton === "rent" ? "active" : ""}
                        onClick={() => handleButtonClick("rent")}
                      >
                        Rental
                      </button>
                    </div>
                  </div>
                  <textarea
                    required
                    placeholder="Property Details *"
                    defaultValue={""}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    maxLength="1024"
                  />
                  <div className="flex align-center mt-25 broad-btns">
                    <button
                      type="submit"
                      className="btn btn--schedule flex align-center"
                      onClick={handleSchedule}
                    >
                      <svg
                        width={23}
                        height={24}
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 21C15.7342 21 19.1667 17.4183 19.1667 13C19.1667 8.58172 15.7342 5 11.5 5C7.26586 5 3.83337 8.58172 3.83337 13C3.83337 17.4183 7.26586 21 11.5 21Z"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5 9V13L13.4167 15"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.79163 3L1.91663 6"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.0834 6L18.2084 3"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.11421 18.7L3.83337 21"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.905 18.6699L19.1667 20.9999"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Schedule
                    </button>
                    <button type="submit" className="btn btn--lunch">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.89998 19.0999C0.999976 15.1999 0.999976 8.7999 4.89998 4.8999"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.79995 16.2C5.49995 13.9 5.49995 10.1 7.79995 7.69995"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.2 7.80005C18.5 10.1 18.5 13.9 16.2 16.3"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.1 4.8999C23 8.7999 23 15.0999 19.1 18.9999"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Launch
                    </button>
                  </div>
                </form>
              </div>
              <div className="dashboard">
                <div className="broad__calender dashboard__calender">
                  <h3 className="cal_title">Calender</h3>
                  <DatePickerCustom />
                </div>
              </div>
            </div>
            <div
              className={`broad__history ${
                activeTab === "history" ? "" : "d-none"
              }`}
            >
              {properties
                ? properties.map((el) => {
                    return (
                      <div key={el._id} className="broad__history-card">
                        <img src={el.images[0]} alt="img" />
                        <h3>{el.title}</h3>
                        <p>{el.location}</p>
                        <p className="date">01 Jan, 2024</p>
                        <div className="broad__history-card-schedule">
                          <div className="flex align-center justify-content-center">
                            <svg
                              width={10}
                              height={10}
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_58_25812)">
                                <path
                                  d="M4.99996 8.75004C6.84091 8.75004 8.33329 7.25766 8.33329 5.41671C8.33329 3.57576 6.84091 2.08337 4.99996 2.08337C3.15901 2.08337 1.66663 3.57576 1.66663 5.41671C1.66663 7.25766 3.15901 8.75004 4.99996 8.75004Z"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 3.75V5.41667L5.83333 6.25"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M2.08337 1.25L0.833374 2.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.16663 2.5L7.91663 1.25"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M2.65829 7.79163L1.66663 8.74996"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.34998 7.77917L8.33331 8.75001"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_58_25812">
                                  <rect width={10} height={10} fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            Schedule Set
                          </div>
                          <div className="broad__history-card-schedule-data">
                            Mar 28, 2024 | 10:00 AM
                          </div>
                        </div>
                        <div className="broad__history-card-actions">
                          <div className="flex align-center">
                            <svg
                              width={15}
                              height={15}
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.625 11.25C10.625 10.9185 10.4933 10.6005 10.2589 10.3661C10.0245 10.1317 9.70652 10 9.375 10H5.625C5.29348 10 4.97554 10.1317 4.74112 10.3661C4.5067 10.6005 4.375 10.9185 4.375 11.25"
                                stroke="#EEEEEE"
                                strokeOpacity="0.933333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.875 2.5H3.125C2.43464 2.5 1.875 3.05964 1.875 3.75V12.5C1.875 13.1904 2.43464 13.75 3.125 13.75H11.875C12.5654 13.75 13.125 13.1904 13.125 12.5V3.75C13.125 3.05964 12.5654 2.5 11.875 2.5Z"
                                stroke="#EEEEEE"
                                strokeOpacity="0.933333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.5 7.5C8.19036 7.5 8.75 6.94036 8.75 6.25C8.75 5.55964 8.19036 5 7.5 5C6.80964 5 6.25 5.55964 6.25 6.25C6.25 6.94036 6.80964 7.5 7.5 7.5Z"
                                stroke="#EEEEEE"
                                strokeOpacity="0.933333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 1.25V2.5"
                                stroke="#EEEEEE"
                                strokeOpacity="0.933333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 1.25V2.5"
                                stroke="#EEEEEE"
                                strokeOpacity="0.933333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            256
                          </div>
                          <button>
                            <img src="assets/icons/icon-delete.svg" alt="img" />
                          </button>
                        </div>
                      </div>
                    );
                  })
                : null}
              <div className="broad__history-card">
                <img src="assets/images/broad-history.png" alt="img" />
                <h3>The Palm View Residence</h3>
                <p>Palm Jumeirah, Dubai</p>
                <p className="date">01 Jan, 2024</p>
                <div className="broad__history-card-schedule">
                  <div className="flex align-center justify-content-center">
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_58_25812)">
                        <path
                          d="M4.99996 8.75004C6.84091 8.75004 8.33329 7.25766 8.33329 5.41671C8.33329 3.57576 6.84091 2.08337 4.99996 2.08337C3.15901 2.08337 1.66663 3.57576 1.66663 5.41671C1.66663 7.25766 3.15901 8.75004 4.99996 8.75004Z"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 3.75V5.41667L5.83333 6.25"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.08337 1.25L0.833374 2.5"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.16663 2.5L7.91663 1.25"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.65829 7.79163L1.66663 8.74996"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.34998 7.77917L8.33331 8.75001"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_58_25812">
                          <rect width={10} height={10} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Schedule Set
                  </div>
                  <div className="broad__history-card-schedule-data">
                    Mar 28, 2024 | 10:00 AM
                  </div>
                </div>
                <div className="broad__history-card-actions">
                  <div className="flex align-center">
                    <svg
                      width={15}
                      height={15}
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.625 11.25C10.625 10.9185 10.4933 10.6005 10.2589 10.3661C10.0245 10.1317 9.70652 10 9.375 10H5.625C5.29348 10 4.97554 10.1317 4.74112 10.3661C4.5067 10.6005 4.375 10.9185 4.375 11.25"
                        stroke="#EEEEEE"
                        strokeOpacity="0.933333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.875 2.5H3.125C2.43464 2.5 1.875 3.05964 1.875 3.75V12.5C1.875 13.1904 2.43464 13.75 3.125 13.75H11.875C12.5654 13.75 13.125 13.1904 13.125 12.5V3.75C13.125 3.05964 12.5654 2.5 11.875 2.5Z"
                        stroke="#EEEEEE"
                        strokeOpacity="0.933333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 7.5C8.19036 7.5 8.75 6.94036 8.75 6.25C8.75 5.55964 8.19036 5 7.5 5C6.80964 5 6.25 5.55964 6.25 6.25C6.25 6.94036 6.80964 7.5 7.5 7.5Z"
                        stroke="#EEEEEE"
                        strokeOpacity="0.933333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 1.25V2.5"
                        stroke="#EEEEEE"
                        strokeOpacity="0.933333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 1.25V2.5"
                        stroke="#EEEEEE"
                        strokeOpacity="0.933333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    256
                  </div>
                  <button>
                    <img src="assets/icons/icon-delete.svg" alt="img" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="broad flex align-center justify-content-center"
            style={{ height: "calc(100% - 154px)" }}
          >
            <p style={{ color: "white" }}>
              Connect WhatsApp number to send broadcast.
            </p>
          </div>
        )}

        {isPopup && (
          <div className="leads-popup leads-popup--delete">
            <div className="leads-popup__container flex justify-content-center align-center">
              <img
                src={loader}
                style={{ height: "150px", display: isLoading }}
              />
              <h3 style={{ color: popupText == "Success" ? "green" : "red" }}>
                {popupText}
              </h3>
            </div>
          </div>
        )}

        {isPickFile && (
          <div className="leads-popup leads-popup--delete add-event-container">
            <div className="leads-popup__container flex justify-content-center align-center add-event-popup">
              <h3
                style={{
                  paddingBottom: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {isError && (
                  <span style={{ color: "red" }}>Missing Recepients</span>
                )}
                <br />
                Upload Contacts
              </h3>
              <form className="flex justify-content-center align-center add-event-popup">
                <CSVReader onData={handleDataFromChild} />
                <div className="flex align-center broad-btns contact-upload-btns-cont">
                  <button
                    type="submit"
                    className="btn btn--schedule flex align-center contacts-upload-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPickFile(false);
                      setList({});
                      setIsError(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn--lunch contacts-upload-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPickFile(false);
                      setIsError(false);
                    }}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default Broad;
