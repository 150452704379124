import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const Leads = () => {
  const [activeTab, setActiveTab] = useState("simple");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [leads, setLeads] = useState(false);
  const [editLead, setEditLead] = useState(false);
  const [tempLead, setTempLead] = useState(false);
  const [deleteID, setDeleteID] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleEditClick = () => {
    setIsReadOnly(!isReadOnly);
  };

  const handleClick = () => {
    setIsPopupVisible(true);
    setIsOverlayVisible(true);
  };

  const handleSaveLead = async () => {
    console.log("temp lead: ", tempLead);
    let response = await axios.post("/api/leads", tempLead);
    console.log("response: ", response, tempLead);
    if (response.status == 200) {
      setLeads(response.data);
    } else {
      console.log(response);
    }
    setTempLead(false);
  };

  const handleDeleteLead = async (id) => {
    console.log("delete lead: ", id);
    let response = await axios.post("/api/lead/delete", { id: id });
    console.log("response: ", response, tempLead);
    if (response.status == 200) {
      setLeads(response.data);
    } else {
      console.log(response);
    }
    setTempLead(false);
  };

  useEffect(() => {
    const leadsCardElements = document.querySelectorAll(".leads__card");
    const leadsTableRows = document.querySelectorAll(
      ".leads__container--table tbody tr"
    );

    const handleClick = () => {
      setIsPopupVisible(true);
      setIsOverlayVisible(true);
    };

    leadsCardElements.forEach((leadsCardElement) => {
      leadsCardElement.addEventListener("click", handleClick);
    });

    leadsTableRows.forEach((leadsTableRows) => {
      leadsTableRows.addEventListener("click", handleClick);
    });

    return () => {
      leadsCardElements.forEach((leadsCardElement) => {
        leadsCardElement.removeEventListener("click", handleClick);
      });

      leadsTableRows.forEach((leadsTableRows) => {
        leadsTableRows.removeEventListener("click", handleClick);
      });
    };
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      let leads = await axios.get("/api/leads");
      console.log("leads: ", leads);
      setLeads(leads.data);
    };

    fetchLeads();
  }, []);

  useEffect(() => {
    setTempLead(editLead);
  }, [editLead]);

  return (
    <main className="flex">
      <Sidebar title="Leads" />
      <div className="main-container">
        <Header />
        <div className="leads">
          <div className="leads__header flex align-center justify-content-between">
            <div className="leads__preview flex align-center">
              <button
                className={`simple-view ${
                  activeTab === "simple" ? "active" : ""
                }`}
                onClick={() => handleTabClick("simple")}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V9M9 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9M9 3V21M3 9V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H9M3 9H21M21 9V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H9"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <span>
                <svg
                  width="3"
                  height="14"
                  viewBox="0 0 3 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.694 13.232V0.94H2.024V13.232H0.694Z"
                    fill="#0BDEDE"
                  />
                </svg>
              </span>

              <button
                className={`table-view ${
                  activeTab === "table" ? "active" : ""
                }`}
                onClick={() => handleTabClick("table")}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 6H21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 12H21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 18H21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 6H3.01"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 12H3.01"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 18H3.01"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12H17"
                  stroke="#939393"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 18H14"
                  stroke="#939393"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="12.25"
                  y="6.25"
                  width="1.5"
                  height="0.5"
                  rx="0.25"
                  fill="white"
                  stroke="#E5E2E2"
                  strokeWidth="0.5"
                />
                <line
                  x1="12.043"
                  y1="5.84839"
                  x2="13.9566"
                  y2="5.84839"
                  stroke="#D9D9D9"
                />
                <line
                  x1="12.043"
                  y1="6.18555"
                  x2="13.9566"
                  y2="6.18555"
                  stroke="#D9D9D9"
                />
                <path
                  d="M3 6H21"
                  stroke="#939393"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div
            className={`leads__container leads__container--simple ${
              activeTab === "simple" ? "" : "d-none"
            }`}
          >
            {leads
              ? leads.map((lead) => {
                  return (
                    <div
                      key={lead._id}
                      className="leads__card"
                      onClick={() => {
                        setEditLead(lead);
                        setIsReadOnly(false);
                        handleClick();
                      }}
                    >
                      <div className="leads__card-info">
                        <div>
                          <h4>{lead.name}</h4>
                          <p>{lead.property}</p>
                        </div>

                        <p className="leads__card-level">
                          <img
                            src="assets/icons/icon-level.svg"
                            alt="level"
                            aria-hidden="true"
                          />
                          Level {lead.level}
                        </p>
                      </div>

                      <div className="leads__card-status">
                        <p>
                          <b>status:</b> {lead.status}
                        </p>
                        <p>
                          <b>Budget:</b> {lead.budget}
                        </p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          <div
            className={`leads__container leads__container--table ${
              activeTab === "table" ? "" : "d-none"
            }`}
          >
            <table>
              <thead>
                <tr className="thead">
                  <td>First Name</td>
                  <td>Last Name</td>
                  <td>Source</td>
                  <td>Source Title</td>
                  <td>Lead Weight</td>
                  <td>Budget</td>
                  <td>Contact No.</td>
                  <td>Email</td>
                  <td>Status</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </thead>

              <tbody>
                {leads
                  ? leads.map((lead) => {
                      return (
                        <tr key={lead._id}>
                          <td>{lead.name.split(" ")[0]}</td>
                          <td>{lead.name.split(" ")[1]}</td>
                          <td>{lead.source}</td>
                          <td>{lead.property}</td>
                          <td>Level {lead.level}</td>
                          <td>{lead.budget}</td>
                          <td>{lead.phone}</td>
                          <td>{lead.email}</td>
                          <td>{lead.status}</td>
                          <td>
                            <button
                              onClick={() => {
                                setEditLead(lead);
                                setIsReadOnly(false);
                                setTempLead(lead);
                                handleClick();
                              }}
                            >
                              <img
                                src="assets/icons/icon-edit.svg"
                                alt="icon-edit"
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                setIsDelete(true);
                                setDeleteID(lead._id);
                                setIsPopupVisible(false);
                              }}
                            >
                              <img
                                src="assets/icons/icon-delete.svg"
                                alt="icon-delete"
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isOverlayVisible && (
        <div
          className="leads-overlay"
          onClick={() => {
            setIsOverlayVisible(false);
            setIsPopupVisible(false);
            setIsDelete(false);
            setEditLead(false);
          }}
        ></div>
      )}

      {isPopupVisible && (
        <div className="leads-popup">
          <div className="leads-popup__container">
            <div className="leads-popup__inner">
              <div className="leads-popup__col">
                <div className="leads-popup__row">
                  <span>Name:</span>
                  <input
                    type="text"
                    defaultValue={editLead.name}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.name = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Level:</span>
                  <span className="span">
                    {editLead.level}
                    <img
                      src="assets/icons/icon-level.svg"
                      alt="level"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="leads-popup__row">
                  <span>Budget:</span>
                  <input
                    type="number"
                    defaultValue={editLead.budget}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.budget = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Property:</span>
                  <input
                    type="text"
                    defaultValue={editLead.property}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.property = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Status:</span>
                  <span className="span">{editLead.status}</span>
                  {/* <input
                    type="text"
                    defaultValue={editLead.status}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.status = e.target.value;
                      setTempLead(copyLead);
                    }}
                  /> */}
                </div>
              </div>

              <div className="leads-popup__col leads-popup__col--large">
                <div className="leads-popup__row">
                  <span>Mobile Number:</span>
                  <span className="span">{editLead.phone}</span>
                </div>
                <div className="leads-popup__row">
                  <span>Email Address:</span>
                  <input
                    type="text"
                    defaultValue={editLead.email}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.email = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Source:</span>
                  <input
                    type="text"
                    defaultValue={editLead.source}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.source = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Fund Source:</span>
                  {/* <span className="span">{editLead.fundsource}</span> */}
                  <select
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.fundsource = e.target.value;
                      setTempLead(copyLead);
                    }}
                  >
                    <option
                      selected={
                        editLead.fundsource == "Cash" ? "selected" : null
                      }
                    >
                      Cash
                    </option>
                    <option
                      selected={
                        editLead.fundsource == "Mortgage" ? "selected" : null
                      }
                    >
                      Mortgage
                    </option>
                  </select>
                </div>
                <div className="leads-popup__row">
                  <span>Resident / Non Resident:</span>
                  <select
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.resident = e.target.value;
                      setTempLead(copyLead);
                    }}
                  >
                    <option
                      selected={
                        editLead.resident == "Resident" ? "selected" : null
                      }
                    >
                      Resident
                    </option>
                    <option
                      selected={
                        editLead.resident == "Non-resident" ? "selected" : null
                      }
                    >
                      Non-resident
                    </option>
                  </select>
                  {/* <input
                    type="text"
                    defaultValue={editLead.resident}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.resident = e.target.value;
                      setTempLead(copyLead);
                    }}
                  /> */}
                </div>
              </div>
            </div>

            <div className="input-group">
              <button
                className="btn dashboard__btn inline-block"
                onClick={() => {
                  setIsPopupVisible(false);
                  setIsReadOnly(true);
                  setIsOverlayVisible(false);
                  handleSaveLead();
                }}
              >
                Save
              </button>
              <button
                className="btn dashboard__btn_white inline-block"
                onClick={() => {
                  setIsPopupVisible(false);
                  setIsReadOnly(true);
                  setIsOverlayVisible(false);
                  setEditLead(false);
                  setTempLead(false);
                }}
              >
                {" "}
                Cancel
              </button>
            </div>

            <button className="leads-popup__edit" onClick={handleEditClick}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1857_14497)">
                  <path
                    d="M10.625 1.87503C10.7842 1.68693 10.9811 1.53382 11.2031 1.42546C11.4252 1.3171 11.6676 1.25587 11.9148 1.24567C12.162 1.23547 12.4086 1.27652 12.639 1.36621C12.8693 1.4559 13.0784 1.59227 13.2527 1.76661C13.427 1.94095 13.5629 2.14945 13.6516 2.37881C13.7403 2.60817 13.7799 2.85337 13.7679 3.09877C13.7559 3.34417 13.6925 3.5844 13.5818 3.80414C13.4711 4.02387 13.3155 4.2183 13.125 4.37503L4.6875 12.8125L1.25 13.75L2.1875 10.3125L10.625 1.87503Z"
                    stroke="#9A9A9A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 3.125L11.875 5.625"
                    stroke="#9A9A9A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1857_14497">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      )}

      {isDelete && deleteID && (
        <div className="leads-popup leads-popup--delete">
          <div className="leads-popup__container">
            <h3>Delete?</h3>
            <p>This action cannot be undone</p>

            <div className="input-group">
              <button
                className="btn dashboard__btn inline-block"
                onClick={() => {
                  setIsDelete(false);
                  setIsOverlayVisible(false);
                  handleDeleteLead(deleteID);
                }}
              >
                Delete
              </button>
              <button
                className="btn dashboard__btn_white inline-block"
                onClick={() => {
                  setIsDelete(false);
                  setDeleteID(false);
                  setIsOverlayVisible(false);
                  setEditLead(false);
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Leads;
