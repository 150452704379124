import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";

const Login = () => {
  useEffect(() => {
    document.body.setAttribute("data-theme", "dark");
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("none");
  const [paused, setPaused] = useState(false);
  const [standalone, setStandalone] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate(); // Get history object from React Router

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        "api/login",
        { username, password },
        {
          withCredentials: true,
        }
      );
      console.log("response", response); // Display a success message
      // Redirect to another page upon successful login
      navigate(0); // Replace '/dashboard' with the desired URL
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.data.message == "paused") {
          setStandalone(false);
          setPaused(true);
        }
        if (error.response.data.message == "standalone_paused") {
          setPaused(false);
          setStandalone(true);
        }
        if (error.response.data.message.includes("incorrect")) {
          setPaused(false);
          setStandalone(false);
        }
        console.error("error", error.response.data.message);
        setErrorMessage("flex");
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
    }
  };

  return (
    <div
      className="main-container"
      style={{ width: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form onSubmit={handleSubmit} autoComplete="off">
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <h2 className="form-title">Welcome Back!</h2>
          <div className="input-group">
            <div
              variant="button"
              fontWeight="regular"
              style={{
                marginBottom: "1rem",
                color: "red",
                display: errorMessage,
              }}
            >
              {standalone
                ? "Your account has not been activated."
                : paused
                ? "Your account has been paused. Please contact your admin."
                : "Incorrect username or password."}
              {/* {standalone && "Your account has not been activated."} */}
            </div>
            <label htmlFor="username">Email</label>
            <input
              type="text"
              placeholder="Username"
              className="input user-icon"
              autoComplete="none"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="username">Password</label>
            <input
              type="password"
              placeholder="Password"
              className="input password-icon"
              autoComplete="none"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link
              to="/forgot-password"
              style={{
                color: "#a4a4a4",
                marginTop: "1rem",
                marginBottom: "1rem",
                float: "right",
                textDecoration: "none",
              }}
            >
              Forgot Password?
            </Link>
          </div>
          <button type="submit" className="btn btn--full-width mt-14">
            Sign in
          </button>
          {/* <div className="continue-with">Or continue with</div>
          <div className="btn-group">
            <button className="login-btn">
              <img src="assets/icons/icon-google.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-apple.svg" alt="google icon" />
            </button>
            <button className="login-btn">
              <img src="assets/icons/icon-facebook.svg" alt="google icon" />
            </button>
          </div> */}
          <div className="new-signup">
            New to Alternative Control?
            <Link to="/signup" className="btn">
              Sign Up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
