import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import axios from "axios";

const KYC = () => {
  const { agent, client } = useParams();

  const [activeTab, setActiveTab] = useState("simple");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [leads, setLead] = useState(false);
  const [editLead, setEditLead] = useState(false);
  const [tempLead, setTempLead] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        let lead = await axios.get(`/api/lead/${agent}/${client}`);
        console.log("leads: ", lead);
        setLead(lead.data[0]);
        setEditLead(lead.data[0]);

        if (lead.data[0].fundsource == "null") {
          setEditLead((prevLead) => ({ ...prevLead, fundsource: "Cash" }));
        }

        if (lead.data[0].resident == "null") {
          setEditLead((prevLead) => ({ ...prevLead, resident: "Resident" }));
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    fetchLead();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleEditClick = () => {
    setIsReadOnly(!isReadOnly);
  };

  const handleClick = () => {
    setIsPopupVisible(true);
    setIsOverlayVisible(true);
  };

  const handleSaveLead = async () => {
    console.log("temp lead: ", tempLead);
    let response = await axios.post(`/api/lead/${agent}/${client}`, tempLead);
    console.log("response: ", response, tempLead);
    if (response.status == 200) {
      setLead(response.data[0]);
      setEditLead(response.data[0]);
    } else {
      console.log(response);
    }
    setTempLead(false);
  };

  useEffect(() => {
    setTempLead(editLead);
  }, [editLead]);

  console.log("lead: ", editLead, editLead.source, typeof editLead.source);

  return (
    <div
      className="flex"
      style={{
        height: "100vh",
        background: "black",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error ? (
        <div
          className="flex"
          style={{
            flexDirection: "column",
            height: "100vh",
            background: "black",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4
            style={{
              color: "red",
              textAlign: "center",
              paddingBottom: ".5rem",
            }}
          >
            Invalid KYC link
          </h4>
          <a
            href="/"
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              textDecoration: "none",
            }}
          >
            &larr; Back home
          </a>
        </div>
      ) : (
        <div className="leads-popup">
          <h1
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Your Details
          </h1>
          <p
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            Please click on the pen icon to edit the form. Fill out all the
            fields to complete KYC.
          </p>
          <div className="leads-popup__container">
            <div className="leads-popup__inner">
              <div className="leads-popup__col">
                <div className="leads-popup__row">
                  <span>Name:</span>
                  <input
                    type="text"
                    defaultValue={
                      editLead.name == "null" ? "Edit Field" : editLead.name
                    }
                    style={{
                      color: editLead.name == "null" ? "grey" : "white",
                    }}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.name = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Level:</span>
                  <span className="span">
                    {editLead.level}
                    <img
                      src="/assets/icons/icon-level.svg"
                      alt="level"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="leads-popup__row">
                  <span>Budget:</span>
                  <input
                    type="number"
                    defaultValue={editLead.budget}
                    placeholder={
                      editLead.budget == "null" ? "Edit Field" : editLead.budget
                    }
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.budget = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Property:</span>
                  <input
                    type="text"
                    defaultValue={
                      editLead.property == "null"
                        ? "Edit Field"
                        : editLead.property
                    }
                    style={{
                      color: editLead.property == "null" ? "grey" : "white",
                    }}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.property = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Status:</span>
                  <span className="span">{editLead.status}</span>
                  {/* <input
                    type="text"
                    defaultValue={editLead.status}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.status = e.target.value;
                      setTempLead(copyLead);
                    }}
                  /> */}
                </div>
              </div>

              <div className="leads-popup__col leads-popup__col--large">
                <div className="leads-popup__row">
                  <span>Mobile Number:</span>
                  <span className="span">{editLead.phone}</span>
                </div>
                <div className="leads-popup__row">
                  <span>Email Address:</span>
                  <input
                    type="email"
                    defaultValue={
                      editLead.email == "null" ? "Edit Field" : editLead.email
                    }
                    style={{
                      color: editLead.email == "null" ? "grey" : "white",
                    }}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.email = e.target.value;
                      setTempLead(copyLead);
                    }}
                  />
                </div>
                <div className="leads-popup__row">
                  <span>Source:</span>
                  <span className="span">{editLead.source}</span>
                  {/* <input
                    type="text"
                    defaultValue={editLead.source}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.source = e.target.value;
                      setTempLead(copyLead);
                    }}
                  /> */}
                </div>
                <div className="leads-popup__row">
                  <span>Funds Source:</span>
                  {!isReadOnly ? (
                    <select
                      onChange={(e) => {
                        let copyLead = { ...tempLead };
                        copyLead.fundsource = e.target.value;
                        setTempLead(copyLead);
                      }}
                    >
                      <option
                        selected={
                          editLead.fundsource == "Cash" ? "selected" : null
                        }
                      >
                        Cash
                      </option>
                      <option
                        selected={
                          editLead.fundsource == "Mortgage" ? "selected" : null
                        }
                      >
                        Mortgage
                      </option>
                    </select>
                  ) : (
                    <span className="span">{editLead.fundsource}</span>
                  )}
                </div>
                <div className="leads-popup__row">
                  <span>Resident / Non Resident:</span>
                  {!isReadOnly ? (
                    <select
                      onChange={(e) => {
                        let copyLead = { ...tempLead };
                        copyLead.resident = e.target.value;
                        setTempLead(copyLead);
                      }}
                    >
                      <option
                        selected={
                          editLead.resident == "Resident" ? "selected" : null
                        }
                      >
                        Resident
                      </option>
                      <option
                        selected={
                          editLead.resident == "Non-resident"
                            ? "selected"
                            : null
                        }
                      >
                        Non-resident
                      </option>
                    </select>
                  ) : (
                    <span className="span">{editLead.resident}</span>
                  )}
                  {/* <input
                    type="text"
                    defaultValue={editLead.resident}
                    readOnly={isReadOnly}
                    onChange={(e) => {
                      let copyLead = { ...tempLead };
                      copyLead.resident = e.target.value;
                      setTempLead(copyLead);
                    }}
                  /> */}
                </div>
              </div>
            </div>

            <div className="input-group">
              <button
                className="btn dashboard__btn inline-block"
                onClick={() => {
                  setIsPopupVisible(false);
                  setIsReadOnly(true);
                  setIsOverlayVisible(false);
                  handleSaveLead();
                }}
              >
                Save
              </button>
              <button
                className="btn dashboard__btn_white inline-block"
                onClick={() => {
                  setIsPopupVisible(false);
                  setIsReadOnly(true);
                  setIsOverlayVisible(false);
                  // setEditLead(false);
                  // setTempLead(false);
                }}
              >
                {" "}
                Cancel
              </button>
            </div>

            <button className="leads-popup__edit" onClick={handleEditClick}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1857_14497)">
                  <path
                    d="M10.625 1.87503C10.7842 1.68693 10.9811 1.53382 11.2031 1.42546C11.4252 1.3171 11.6676 1.25587 11.9148 1.24567C12.162 1.23547 12.4086 1.27652 12.639 1.36621C12.8693 1.4559 13.0784 1.59227 13.2527 1.76661C13.427 1.94095 13.5629 2.14945 13.6516 2.37881C13.7403 2.60817 13.7799 2.85337 13.7679 3.09877C13.7559 3.34417 13.6925 3.5844 13.5818 3.80414C13.4711 4.02387 13.3155 4.2183 13.125 4.37503L4.6875 12.8125L1.25 13.75L2.1875 10.3125L10.625 1.87503Z"
                    stroke="#9A9A9A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.375 3.125L11.875 5.625"
                    stroke="#9A9A9A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1857_14497">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KYC;
